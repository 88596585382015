<template>
  <v-container fluid>
    <!-- Section Path File/Modul -->
    <v-breadcrumbs 
      :items="pathModul"
      divider="-"
      normal
      class="pathModul"
    ></v-breadcrumbs>

    <!-- Section Filtering Input/Select/Button -->
    <v-container fluid :class="$vuetify.breakpoint.lgAndUp? 'px-6' : 'px-1'">

      <v-row align="center">
          
        <v-col
        class="col-input-filter"
        >
            <v-select
            v-on:change="selectValueStation"
            :items="itemStation"
            label="Station"
            prepend-inner-icon="mdi-store-marker"
            hint="Station"
            solo
            dense
            hide-details
            ></v-select>
        </v-col>

        <!-- Column Select Parameter -->
        <v-col
        class="col-input-filter"
        >
            <v-select
            v-model="arraySelectedParam"
            :items="listParam"
            item-text="paramdisplay"
            item-value="paramvalue"
            label="Parameter"
            prepend-inner-icon="mdi-beaker-outline"
            multiple
            persistent-hint
            hide-details
            solo
            dense
            :no-data-text="valStation == null? 'Please select station first' : 'No data available'"
            @change="checkedParam($event)"
            ></v-select>
        </v-col> 

        <!-- Column Select Calendar -->
        <v-col
        class="col-input-filter col-dateYear"
        >
          <v-select
          v-on:change="selectValueDate"
          :items="dropdownDate"
          prepend-inner-icon="mdi-calendar-today"
          label="Year"
          class="input-date-year"
          hide-details
          solo
          dense
          >
          </v-select>
        </v-col>

        <!-- Column Generate Button -->
        <v-col cols="12" sm="6" md="4">
          <v-btn
          :disabled="valStation == null || arraySelectedParam.length == 0 || valDate == null"
          class="white--text button mt-3"
          solo
          dense
          normal
          :loading="loadingReport"
          @click="generateChart"
          >
            GENERATE REPORT
          </v-btn>
        </v-col>

      </v-row>

      <v-card>
        <!-- Main Report Title/Tab -->
        <v-tabs>
            <v-tab>YEARLY</v-tab>
        </v-tabs>

        <div style="max-height: calc(100vh - 360px);overflow-y:auto;overflow-x:hidden;">
          <highcharts 
          :constructorType="'stockChart'" 
          class="hc" 
          :options="chartOptions" 
          ref="chart"
          >
          </highcharts>

          <v-divider class="pb-4"></v-divider>

          <!---- Export Button ---->
          <v-row class="justify-end px-10 pb-0">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                color="excel"
                class="mb-5"
                style="float: right;"
                elevation="2"
                v-bind="attrs"
                v-on="on"
                @click="exportReport()"
                >
                  <v-icon
                  color="white"
                  center
                  dark
                  >
                      mdi-microsoft-excel
                  </v-icon>
                </v-btn>
              </template>
              <span>Export to Excel</span>
            </v-tooltip>
            <!-- <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                color="pdf"
                class="mb-5 mx-2"
                style="float: right;"
                elevation="2"
                v-bind="attrs"
                v-on="on"
                @click="exportReport('pdf')"
                >
                  <v-icon
                  color="white"
                  center
                  dark
                  >
                      mdi-file-pdf-box
                  </v-icon>
                </v-btn>
              </template>
              <span>Export to PDF</span>
            </v-tooltip> -->
            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                <v-btn
                color="csv"
                class="mb-5 mx-2"
                style="float: right;"
                elevation="2"
                v-bind="attrs"
                v-on="on"
                @click="exportCSV()"
                >
                  <v-icon
                  color="white"
                  center
                  dark
                  >
                      mdi-file-delimited
                  </v-icon>
                </v-btn>
              </template>
              <span>Export to CSV</span>
            </v-tooltip>
          </v-row>

          <v-data-table
          :headers="dtheader"
          :items="dtbody"
          :items-per-page="itemPerPage"
          class="elevation-1 px-6 headerDtSarawak"
          fixed-header
          >
          
            <template
            v-slot:body="{ items }"
            >
              <tbody>
                <tr
                  v-for="(item,x) in items"
                  :key="x"
                >
                  <td v-for="(itemtd,i) in dtheader" :key="i" >
                    <!-- {{item[itemtd.value]}} -->
                    <span v-if="item[itemtd.value+'_FLAG'] != null" class="red--text font-weight-bold">
                      {{ item[itemtd.value+'_FLAG'] }}
                    </span>
                    <span v-else>
                      <span v-if="item[itemtd.value] == null" class="font-weight-bold">
                        NA
                      </span>
                      <span v-else>
                        {{ item[itemtd.value] }}
                      </span>
                    </span>
                  </td>
                </tr>
              </tbody>
            </template>
          
          </v-data-table>
        </div>

        <template>
          <v-footer padless>
            <v-col
              class="text-center footer-report"
              cols="12"
            >
              <!-- {{ new Date().getFullYear() }} — <strong>Vuetify</strong> -->
              <span class="one-text-footer">
                <span class="red--text">C</span>
                <span class="blue--text">=CALIBRATION</span>
              </span>
              <span class="one-text-footer">
                <span class="red--text">M</span>
                <span class="blue--text">=MAINTENANCE</span>
              </span>
              <span class="one-text-footer dontBreak">
                <span class="red--text">DOR</span>
                <span class="blue--text">=DATA OUT OF SENSOR RANGE</span>
              </span><br>
              <span class="one-text-footer">
                <span class="red--text">OUTL</span>
                <span class="blue--text">=OUTLIER</span>
              </span>
              <span class="one-text-footer">
                <span class="red--text">NA</span>
                <span class="blue--text">=DATA NOT AVAILABLE</span>
              </span>

            </v-col>
          </v-footer>
        </template>

      </v-card>      

    </v-container>

    <v-dialog 
      v-model="dialogAlert"
      width="500">

      <v-card>
        <v-card-title class="text-h5 white--text error">
            <v-icon class="white--text mr-4">mdi-alert</v-icon>Attention
        </v-card-title>

        <v-card-text class="pa-5 d-flex justify-center">
            <h3>Due to performance issue, you can only select maximum of 2 parameters only.</h3> 
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="dialogAlert = false;"
          >
              Close
          </v-btn>
        </v-card-actions>
      </v-card>
    
    </v-dialog>

  </v-container>
</template>


<script>

import axios from 'axios';
import Papa from "papaparse";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default {
    
  data: () => ({

    dialogAlert: false,
    GlobalDataYearly : [],
    loadingReport: false,
    pathModul: [
        {
            text: 'Home',
            disabled: false,
            href: '/',
            // href: '/Mapviewer',
        },
        {
            text: 'Report - Yearly',
            disabled: true,
            href: '/Report/YearlyReport',
        },
    ],
    itemStation: [],
    dropdownDate: [],
    valStation: null,
    valParameter: null,
    valDate: null,
    stationTitle: null,

    // Title
    locationStation: null,
    stationID: null,

    itemPerPage: 10,

    arraySelectedParam: [],
    listParam: [],

    chartOptions: {
      // chart: {
      //   type: 'spline'
      // },
      title: {
        text: 'Water Quality Status'
      },
      subtitle: {
        text: ''
      },
      series: [],
      legend: {
          enabled: true
      },
    },

    dtbody: [],
    dtheader: [],
    mainParam: [],
    unit: []
      
  }),

  methods: {

    convert12Hours(time) {
      var H = +time.substr(0, 2);
      var h = H % 12 || 12;
      var ampm = (H < 12 || H === 24) ? "AM" : "PM";
      time = h + time.substr(2, 3) + time.substr(5, 3) + ' ' + ampm;
      return time;
    },

    convertDateFormat(date){
      var dd = date.split("-")[2];
      var mm = date.split("-")[1];
      var yyyy = date.split("-")[0];
      date = dd + "/" + mm + "/" + yyyy;
      return date;
    },

    load(){
      // this.getDataParam();
      this.getApiStation();
      this.getApiDateYear();
    },

    getApiStation(){
      axios.get(this.globalUrl+'mqm2/miqims/stations', {
          headers: {
              'Authorization': 'Bearer ' + this.$store.getters.user.token,
          }
      })
      .then((response) => {
          // this.stationDetails = response.data;
          for (let i = 0; i < response.data.length; i++) {
            this.itemStation.push(response.data[i].STATION_ID + ' - ' + response.data[i].LOCATION);
          }
          
          console.log(this.itemStation);

      })
      .catch(error => {
          console.log(error);
      })
    },

    getApiDateYear(){
        axios.get(this.globalUrl+'mqm2/miqims/years', {
            headers: {
                'Authorization': 'Bearer ' + this.$store.getters.user.token,
            }
        })
        .then((response) => {
            // this.stationDetails = response.data;
            console.log(response.data);
            for (let i = 0; i < response.data.length; i++) {
              this.dropdownDate.push(response.data[i]);
            }

            
            // console.log(this.itemStation);

        })
        .catch(error => {
            console.log(error);
        })
    },

    getDataParam(){

      // console.log(this.valStation)

      let stn_id = (this.valStation).split("-")[0];

      // console.log(stn_id)
        axios.get(this.globalUrl+'mqm2/miqims/params?stationid='+stn_id, {
            headers: {
                'Authorization': 'Bearer ' + this.$store.getters.user.token,
            }
        })
        .then((response) => {
            this.mainParam = response.data;
            let data = response.data;
            //var obj;

            let param = [];
            for(let k in data){

              let obj = {
                paramdisplay: data[k].text,
                paramvalue : data[k].reading
              }

              // if(data[k].reading === 'pH'){
              //   obj = {
              //       paramdisplay: 'Sub-index '+ data[k].fullname +' (Index)',
              //       paramvalue : data[k].reading
              //   }
              // }

              // else if(data[k].reading === 'DO_SAT'){
              //   obj = {
              //       paramdisplay: 'Sub-index Dissolved Oxygen (Index)',
              //       paramvalue : data[k].reading
              //   }
              // }

              // else if(data[k].reading === 'TSS'){
              //   obj = {
              //       paramdisplay: 'Sub-index '+ data[k].fullname +' (Index)',
              //       paramvalue : data[k].reading
              //   }
              // }

              // else{
              //   obj = {
              //       paramdisplay: data[k].text,
              //       paramvalue : data[k].reading
              //   }
              // }

              let objunit = {
                  name: data[k].reading,
                  value: data[k].unit
              }

              param.push(obj);
              this.unit.push(objunit);
            }

            this.listParam = param;

            // for (let i = 0; i < response.data.length; i++) {
            //   this.listParam.push(response.data[i].reading);
            // }

        })
        .catch(error => {
            console.log(error);
        })
    },

    // Get Data From Api
    getDataYearly(station,tarikh){

        if(this.valParameter.length > 2) 
        {
          alert('Sorry, due to performance issue, you can only select max 2 params only.');
          this.loadingReport = false;
          return;
        }

        axios.get(this.globalUrl+'mqm2/miqims/yearly?startdt='+ this.valDate +'-01-01&stationid=' + station + '&paramlist=' + this.valParameter, {
            headers: {
                'Authorization': 'Bearer ' + this.$store.getters.user.token,
            }
        })
        .then((response) => {
            // Globalkan Data
            this.GlobalDataYearly = response.data;
            this.loadDataChart(response.data,this.valParameter,station);

        })
        .catch(error => {
            console.log(error);
        })
    },


    // Get value from Selected Station
    selectValueStation: function(e) {
      this.valStation = e;
      this.getDataParam();
    },

    // Get value from Selected Year
    selectValueDate: function(e) {
      this.valDate = e
    },

    // Get value from Selected Parameters
    checkedParam: function(e) {
      if(e.length > 2){
        this.dialogAlert = true;
        this.arraySelectedParam .splice(2, 1);
      }

      else{
        this.valParameter = e;
      }
    },

    // Get value from Selected Date (by Year)
    selectDate: function(e) {
      this.valDate = e;
    },

    // load Data for Rendering Data Series Highchart
    loadDataChart(data,param,stesen){

      this.dtbody = [];
      this.chartOptions.series = [];
      this.chartOptions.title.text = "WATER QUALITY STATUS FOR "+ this.valStation.split("-")[1].toUpperCase() + " ( "+ this.valStation.split("-")[0] + ") " +this.valDate;

      for (let i = 0; i < data.length; i++) {
        let keyData = Object.keys(data[0])
      }

      this.dtheader = [];
      for(let i of Object.keys(data[0])){
        if(i != "name" && i != "time" && i != "epochdate" ){
          if(i == "datetime_a"){
            this.dtheader.push({text:"Date",value:i});
          }

          // else if(i == "station_a"){
          //   this.dtheader.push({text:"STATION ID",value:i});
          // }

          // else if(i == "LOCATION"){
          //   this.dtheader.push({text:"LOCATION",value:i});
          // }

          // else{
          //   this.dtheader.push({text:i,value:i});
          // }
        }
      }

     
      for(let j in this.arraySelectedParam){
        for(let k in this.mainParam){
          if(this.arraySelectedParam[j] === this.mainParam[k].reading){

            // if(this.arraySelectedParam[j] === 'pH'){
            //   this.dtheader.push({text:'Sub-index pH (Index)',value:this.mainParam[k].reading});
            // }

            // else if(this.arraySelectedParam[j] === 'DO_SAT'){
            //   this.dtheader.push({text:'Sub-index Dissolved Oxygen (Index)',value:this.mainParam[k].reading});
            // }

            // else if(this.arraySelectedParam[j] === 'TSS'){
            //   this.dtheader.push({text:'Sub-index Total Suspended Solids (Index)',value:this.mainParam[k].reading});
            // }

            // else{
            //   this.dtheader.push({text:this.mainParam[k].fullname,value:this.mainParam[k].reading});
            // }

// console.log("masuk")
// console.log(this.mainParam, "mp")

            this.dtheader.push({text:this.mainParam[k].fullname+" ("+this.mainParam[k].unit+")",value:this.mainParam[k].reading});

          }
        }
      }

      for(let i in data){

        // if (data[i]["datetime_a"].includes("T")) {
        // let dt = data[i]["datetime_a"].split("T")[0];
        // let t = data[i]["datetime_a"].split("T")[1];

        // let dt2 = dt.split("-")[2] + "/" + dt.split("-")[1] + "/" + dt.split("-")[0] 
                        
        // data[i]["datetime_a"] = dt2 + " " + t;
        //}

    

        data[i]["datetime_a"] = this.convertDateFormat(data[i]["datetime_a"].split("T")[0]);
        data[i]["datetime_a"] =  data[i]["datetime_a"].substring(3);

        let dtime = data[i]["datetime_a"]
        dtime = dtime.split("/")

        let bulan = dtime[0];
        let tahun = dtime[1];

        if (bulan.includes("0") && bulan != 10){
            bulan = bulan.substring(1);
          }

          var months = ["", "January", "February", "March", "April", "May", "June", 
            "July", "August", "September", "October", "November", "December" ];

        let dtmonth = months[bulan] + " " + tahun;

        data[i]["datetime_a"] = dtmonth;
        // data[i]["datetime_a"] = this.convertDateFormat(data[i]["datetime_a"].split("T")[0])  + " " + this.convert12Hours(data[i]["datetime_a"].split("T")[1]);

        // console.log("data",data[i])

        this.dtbody.push(data[i]);
        // if (data[i]["datetime_a"].includes("T")) {
                        
        //   let dt = data[i]["datetime_a"].split("T")[0];
        //   let t = data[i]["datetime_a"].split("T")[1];
        //   let dt2 = dt.split("-")[2] + "/" + dt.split("-")[1] + "/" + dt.split("-")[0];
        //   data[i]["datetime_a"] = dt2 + " " + t;
        // }
        // this.dtbody.push(data[i]);
      }

      // Clear first chart before generate another new chart
      //this.chartOptions.series = [];

      // Create object data to put in series chart formating 
      for(let i = 0; i < param.length; i++){
        for(let k in this.unit){
          if(param[i] === this.unit[k].name){
            let obj = {
              name: param[i] + " " + ("["+ this.unit[k].value +"]" ?? ""),
              data: []
            }
            
            data.forEach(function(res,k){
              obj.data.push([res.epochdate,res[param[i]]]);
            });

            this.chartOptions.series.push(obj);
          }
        }
      }

      this.loadingReport = false;

    },

    exportCSV(){

      let headerExportCsv = [];
      for(let i in this.dtheader){
        headerExportCsv.push(this.dtheader[i]['value']);
      }

      let keys = headerExportCsv,
      resultAfterFilter = this.dtbody.map(o => Object.assign(...keys.map(k => ({ [k]: o[k] }))));

      let obj = resultAfterFilter;

      for(let i in obj){

        obj[i]["Date"] =  obj[i]['datetime_a']; 
        // obj[i]["STATION ID"] =  obj[i]['station_a']; 
        // obj[i]["LOCATION "] =  obj[i]['LOCATION']; 

        delete obj[i]['datetime_a'];
        delete obj[i]['station_a'];
        delete obj[i]['LOCATION'];

        for(let j in this.mainParam){
          for(let k in this.arraySelectedParam){
            if(this.arraySelectedParam[k] === this.mainParam[j].reading){
              obj[i][this.mainParam[j].text] = obj[i][this.mainParam[j].reading];
              delete obj[i][this.mainParam[j].reading];
            }
          }
        }

      }

      let blob = new Blob([Papa.unparse(obj)], { type: 'text/csv;charset=utf-8;' });
      let link = document.createElement("a");
      let url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", 'Yearly Report.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

    },

    exportReport(){

      // Define your style class template.

      this.itemPerPage = -1;

        let headerName = "<tr = 'border:none !important' ><td colspan ='3' style = 'text-align:center; border:none !important; font-weight: bold'>WATER QUALITY STATUS FOR "+ this.valStation.split("-")[1] + "( "+ this.valStation.split("-")[0] + ") " +this.valDate + "</td></tr>"
        var style = "<style> th, td { border: 10px solid;}</style>";

        var uri = "data:application/vnd.ms-excel;base64,",
          template =
            '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]-->' +
            style +
            "</head><body><table>" +
            headerName +
            "{table}</table></body></html>",
          base64 = function (s) {
            return window.btoa(unescape(encodeURIComponent(s)));
          },
          format = function (s, c) {
            return s.replace(/{(\w+)}/g, function (m, p) {
              return c[p];
            });
          };


        setTimeout(() => {
          let table = document.querySelectorAll(".v-data-table__wrapper ");

          // console.log(table[0].innerHTML)

          let tablei = table[0].innerHTML;
          // if (tablei.includes("℃")){
          tablei = tablei.replaceAll("°C", "&#176;C").replaceAll("µ", "&#181;");


          let ctx = { worksheet: "Yearly Report" || "Worksheet", table: tablei };

          let a = document.createElement("a");
          a.href = uri + base64(format(template, ctx));
          a.download = "Yearly Report.xls";
          //triggering the function
          a.click();

          this.itemPerPage = 10;

        }, 1000)

    },

    // Generate Chart
    generateChart(){
      this.loadingReport = true;
      this.getDataYearly(this.valStation.split("-")[0],this.valDate);
    },

    handler() {
      var args = arguments;
      for (var arg of args) {
        if (arg instanceof Function) {
          arg();
        }
      }
    },
    
  },

  mounted() {
    this.load();
  }

}
</script>



<style>

     /* Section Path File/Modul */
    .pathModul{
      padding-bottom: 0px;
    }

    .pathModul > li:nth-child(3) > a{
      color: black !important;
    }

    /* Section Filtering Input/Select/Button */
    .container-filtering{
      padding: 0px;
    }

    /* .col-input-filter{
      padding: 0px 10px;
    } */
    .col-dateYear{
      display: flex !important;
    }

    .input-date-year{
      margin-left: 12px;
    }

    .col-btn-generateReport{
        margin-bottom: 18px;
    }

    .btn-generate{
        margin: 0px !important;
        /* background: #4495D1 !important; */
    }

    .v-text-field.v-text-field--solo .v-input__control,
    .btn-generate .v-btn .v-btn--contained{
        min-height: 10px;
        margin-top: 10px;
    }

    /* Section Chart Form */
    .highcharts-credits{
      display: none;
    }

    /* Footer Report */
    .one-text-footer{
      margin: 0px 10px;
    }


</style>